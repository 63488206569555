<template>
	<div class="bigDiv">
		<div>
			<!-- 面包屑导航 -->
			<el-breadcrumb separator-class="el-icon-arrow-right">
			  <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
			  <el-breadcrumb-item>数据统计</el-breadcrumb-item>
			  <el-breadcrumb-item>库存统计</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div style="margin-top:20px">
			<el-card>
				<el-row :gutter="10">
					<el-col :span="8">
						<el-input placeholder="请输入需要查询的门店ID" v-model="queryInfo.query" class="input-with-select" clearable @clear="seachVal">
						<el-button slot="append" icon="el-icon-search" @click="ListArr()"></el-button>
						</el-input>
					</el-col>
				</el-row>
				<el-table
				      :data="ArrList"
				      style="width: 100%">
					  <el-table-column
						type="index"
						width="100">
					  </el-table-column>
				      <el-table-column
				        prop="SID"
				        label="门店ID"
				        width="150">
				      </el-table-column>
				      <el-table-column
				        prop="MATTCODE"
				        label="产品编码"
				        width="250">
				      </el-table-column>
				      <el-table-column
				        prop="MATTNAME"
				        label="产品名称"
						width="500">
				      </el-table-column>
					  <el-table-column
					    prop="UNIT"
					    label="单位">
					  </el-table-column>
					  <el-table-column
					    prop="JSKC"
					    label="库存">
					  </el-table-column>
				    </el-table>
					<!-- 分页功能 -->
					<div style="margin-top: 20px;">
						<el-pagination
						  @size-change="SizeChange"
						  @current-change="CurrentChange"
						  :current-page="queryInfo.pagenum"
						  :page-sizes="[10, 30, 50]"
						  :page-size="queryInfo.pagesize"
						  layout="total, sizes, prev, pager, next, jumper"
						  :total="total">
						</el-pagination>
					</div>
			</el-card>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return{
				queryInfo:{
					query:'',
					pagenum:1,
					pagesize:10
				},
				ArrList: [],
				total:0
			}
		},
		methods:{
			//清空input
			seachVal(){
				this.ListArr()
			},
			//获取列表
			ListArr(){
				this.ArrList = []
				let parm = new URLSearchParams()
				let getUserSession = JSON.parse(sessionStorage.getItem('userSession'));
				// console.log(getUserSession.ID);
				parm.append('query',this.queryInfo.query);
				parm.append('TOKEN',getUserSession.token);
				this.$axios.post('/jskclist.php',parm)
				.then(res=>{
					// console.log(res)
					if (res.data.code == 200){
						this.ArrList = res.data.data
					}
				})
			},
			// 每页数据条数发生改变触发
			SizeChange(newsize){
				this.queryInfo.pagesize = newsize;
				this.getUser();
			},
			// 当前页码发生改变触发
			CurrentChange(newnum){
				this.queryInfo.pagenum = newnum;
				this.getUser();
			}
		},
		created() {
			
		}
	}
</script>

<style>
	.bigDiv{font-family: 微软雅黑,"microsoft yahei";}
	.drawer{padding: 20px;}
	.d_header{display: flex;align-items: center;}
	.d_header h1{font-weight: 100;font-size: 25px;font-family: 微软雅黑;float: left;padding: 0;margin: 0;}
	.d_header span {color: #ccc;}
</style>
